import gojus from "@/services/gojus";

import { Task, TaskForm, TaskRecurrenceFormT, TaskReschedule, TaskSummary, TaskType } from "@/store/task/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_TASK_URL } = process.env;

export class TaskService {
  endpoint = "tarefas/";
  postFileEndPoint = "tarefas/arquivos";
  recurrence = "tarefa-recorrencias/";
  recurrenceId = (cd: number) => `tarefa-recorrencias/${cd}/`;
  getFileEndPoint = (cd: number) => `tarefas/arquivos/${cd}`;
  counterEndPoint = (cd_arquivo: number, cd_tarefa: number) => `tarefas/arquivos/contador/${cd_arquivo}/${cd_tarefa}`;
  deleteFileEndPoint = (cd_arquivo: number, cd_tarefa: number) => `tarefas/arquivos/${cd_arquivo}/${cd_tarefa}`;
  taskOnDayEndpoint = "tarefas/cadastros-dia/";
  cdEndpoint = (cd: number) => `tarefas/${cd}/`;
  changeEquipeEndpoint = (cd: number | string) => `tarefas/${cd}/trocar-equipe/`;
  typeEndpoint = "tipo-tarefas/";
  summaryEndPoint = "tarefas/resumo/";
  typeCDEndpoint = (cd: number) => `tipo-tarefas/${cd}/`;
  cancelEndPoint = (cd: number) => `tarefas/cancelar/${cd}/`;
  finishEndpoint = (cd: number) => `tarefas/finalizar/${cd}/`;
  rescheduleEndpoint = (cd: number) => `tarefas/reagendar/${cd}/`;
  responsibleEndpoint = (cd: number) => `tarefas/solicitar-troca/${cd}/`;
  acceptTask = (cd: number) => `tarefas/aceitar-troca/${cd}/`;
  recuseTask = (cd: number) => `tarefas/recusar-troca/${cd}/`;
  responsibleTaskOnDayEndPoint = (cd: number) => `tarefas/prazos-dia-responsavel/${cd}/`;

  cancelToken: { [key: string]: any } = { get: null, getAll: null };

  async get(cd: number) {
    const response = await gojus.get<Task>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async getAll(pagination: PaginationParams, filters: any) {
    if (this.cancelToken.getAll) this.cancelToken.getAll.cancel();

    if (filters.etiquetas?.length) {
      filters.etiquetas = filters.etiquetas.map((item: any) => item.id);
    }

    this.cancelToken.getAll = gojus.getCancelToken();
    const response = await gojus.get<PaginationResponse<Task>>({
      entity: this.endpoint,
      config: {
        query: { ...filters, ...pagination },
        baseAPI: VUE_APP_API_TASK_URL,
        cancelToken: this.cancelToken.getAll?.token,
      },
    });

    return response;
  }

  async getTaskOnDay(pagination: PaginationParams, filters: any) {
    const response = await gojus.get<PaginationResponse<Task>>({
      entity: this.taskOnDayEndpoint,
      config: {
        query: { ...filters, ...pagination },
        baseAPI: VUE_APP_API_TASK_URL,
      },
    });

    return response;
  }

  async responsibleTaskOnDay(cd: number, dt_vencimento: string) {
    const response = await gojus.get<any>({
      entity: this.responsibleTaskOnDayEndPoint(cd),
      config: { query: { dt_vencimento }, baseAPI: VUE_APP_API_TASK_URL },
    });
    return response;
  }

  async create(task: TaskForm) {
    const response = await gojus.post<Task, TaskForm>({
      entity: this.endpoint,
      data: task,
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async edit(cd: number, task: TaskForm) {
    const response = await gojus.patch<Task, TaskForm>({
      entity: this.cdEndpoint(cd),
      data: task,
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async editTeam(cd: number, form: { cd_equipe: number; nm_equipe: string }) {
    const response = await gojus.patch({
      entity: this.changeEquipeEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async createFile(cd: number, file: File, cd_empresa?: number) {
    const response = await gojus.post<any>({
      entity: this.postFileEndPoint,
      data: this.handleFile(cd, file),
      config: { query: { cd_empresa }, baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async getCounterFile(cd_arquivo: number, cd_tarefa: number) {
    const response = await gojus.get({
      entity: this.counterEndPoint(cd_arquivo, cd_tarefa),
      config: {
        baseAPI: VUE_APP_API_TASK_URL,
      },
    });

    return response;
  }

  async removeFile(cd_tarefa: number, cd_arquivo: number) {
    const response = await gojus.delete({
      entity: this.deleteFileEndPoint(cd_arquivo, cd_tarefa),
      config: {
        baseAPI: VUE_APP_API_TASK_URL,
      },
    });

    return response;
  }

  async requestResponsible(cd: number, cd_usuario_solicitado: number) {
    const response = await gojus.post({
      entity: this.responsibleEndpoint(cd),
      data: { cd_usuario_solicitado },
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async acceptChangeResponsible(cd_tarefa: number) {
    const response = await gojus.patch<Task, TaskForm>({
      entity: this.acceptTask(cd_tarefa),
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });
    return response;
  }

  async recuseChangeResponsible(cd_tarefa: number) {
    const response = await gojus.delete({
      entity: this.recuseTask(cd_tarefa),
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async getRecurrence(payload: { cd_tarefa: number }) {
    const response = await gojus.get<any[]>({
      entity: this.recurrence,
      config: { query: { cd_tarefa: payload.cd_tarefa }, baseAPI: VUE_APP_API_TASK_URL },
    });

    return response?.data?.[0];
  }

  async addRecurrence(taskRecurrence: TaskRecurrenceFormT) {
    const response = await gojus.post<any, TaskRecurrenceFormT>({
      entity: this.recurrence,
      data: taskRecurrence,
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async editRecurrence(taskRecurrence: TaskRecurrenceFormT) {
    const response = await gojus.patch<any, TaskRecurrenceFormT>({
      entity: this.recurrenceId(taskRecurrence.id as any),
      data: taskRecurrence,
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async delete(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async getAllFiles(cd_tarefa: number, cd_empresa?: number) {
    const response = await gojus.get<any[]>({
      entity: this.getFileEndPoint(cd_tarefa),
      config: {
        query: { cd_empresa },
        baseAPI: VUE_APP_API_TASK_URL,
      },
    });

    return response.data;
  }

  async cancel(cd: number, ds_justificativa_cancelamento: string) {
    const { data } = await gojus.patch({
      entity: this.cancelEndPoint(cd),
      data: { ds_justificativa_cancelamento },
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return { data };
  }

  async finish(cd: number, ds_detalhes_conclusao: string) {
    const { data } = await gojus.patch({
      entity: this.finishEndpoint(cd),
      data: { ds_detalhes_conclusao },
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return { data };
  }

  async reschedule(cd: number, form: TaskReschedule) {
    const response = await gojus.patch({
      entity: this.rescheduleEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async getType(cd: number) {
    const response = await gojus.get<TaskType>({
      entity: this.typeCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async getTypes() {
    const response = await gojus.get<TaskType[]>({
      entity: this.typeEndpoint,
      config: { baseAPI: VUE_APP_API_TASK_URL },
    });

    return response;
  }

  async getSummary(filters?: any) {
    const { data } = await gojus.get<TaskSummary>({
      entity: this.summaryEndPoint,
      config: {
        query: filters,
        baseAPI: VUE_APP_API_TASK_URL,
      },
    });

    return { data };
  }

  handleFile(cd: number, file: File) {
    const data = new FormData();

    data.append("cd_tarefa", cd.toString());
    data.append("aq_arquivo", file, file.name);

    return data;
  }
}

export default TaskService;
