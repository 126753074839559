import Vue from "vue";
import money from "v-money";
import PrimeVue from "primevue/config";
import { createPinia, PiniaVuePlugin } from "pinia";
import { createHead } from "@unhead/vue";
import { UnheadPlugin } from "@unhead/vue/vue2";
import * as Sentry from "@sentry/vue";

import store from "@/store";
import router from "@/router";
import i18n from "@/i18n";

import "@/plugins/primevue";
import "@/assets/index.scss";

import App from "@/app.vue";
import "./registerServiceWorker";

const pinia = createPinia();
Vue.use(PiniaVuePlugin);

const head = createHead();
Vue.use(UnheadPlugin);

Vue.use(money);
Vue.use(PrimeVue, {
  locale: i18n.primevue.locale,
  ripple: true,
});
Vue.config.productionTip = false;

if (process.env.NODE_ENV === "production" && process.env.VUE_APP_MODE === "prod") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

Vue.directive("uppercase", {
  inserted(element: any, _, vNode: any) {
    element.addEventListener("input", (event: any) => {
      event.target.value = event.target.value.toUpperCase();
      vNode.componentInstance.$emit("input", event.target.value);
    });
  },
});

new Vue({
  router,
  store,
  pinia,
  unhead: head,
  render: (h: any) => h(App),
} as any).$mount("#app");
