import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import gojus from "@/services/gojus";
import { generatePaginationOptions } from "@/utils/pagination";

import { Pagination, PaginationResponse } from "@/store/types";
import { IntegrationWritT } from "./types";
import { IntegrationSystemT } from "../types";
import { Employee } from "@/store/department/employee/types";

const { VUE_APP_API_PROCESS_URL } = process.env;

@Module({ namespaced: true })
class IntegrationWritModule extends VuexModule {
  list: IntegrationWritT[] = [];
  pagination: Pagination = { row: 10, total: 0, current: 1 };

  summary: { [key: string]: any } = {};

  @Mutation
  setList(writs: IntegrationWritT[]) {
    this.list = writs;
  }

  @Mutation
  setPagination(payload: Partial<Pagination>) {
    this.pagination = { ...this.pagination, ...payload };
  }

  @Mutation
  setSummary(summary: { [key: string]: any }) {
    this.summary = summary;
  }

  @Action
  async getList(payload?: { filters?: any }) {
    try {
      const filters = { ...payload?.filters };
      if (filters.meus_processos) {
        filters.cd_responsavel_tarefa = this.context.rootState.user.current?.id;
      }

      const response = await gojus.get<PaginationResponse<IntegrationWritT>>({
        entity: "/andamentos/?mod_alvara=1",
        config: {
          query: { ...generatePaginationOptions(this.pagination), ...filters },
          baseAPI: VUE_APP_API_PROCESS_URL,
          useEstablishment: true,
        },
      });
      const writs = response?.data?.results || [];

      if (!this.context.rootState.integration.systems.length) {
        await this.context.dispatch("integration/getSystems", {}, { root: true });
      }
      const systems: IntegrationSystemT[] = this.context.rootState.integration.systems;

      const responsibleIds = [...new Set(writs.map((item) => item.process_obj?.cd_responsavel_tarefa).filter(Boolean))];
      const responsibleList: Employee[] = [];
      if (responsibleIds.length) {
        const responsibleResponse = await this.context.dispatch(
          "department/employee/search",
          { cd_authuser: responsibleIds },
          { root: true }
        );
        responsibleList.push(...(responsibleResponse?.data?.results || []));
      }

      writs.forEach((writ) => {
        if (writ.process_obj?.id) {
          const system = systems.find((s) => s.id === Number(writ.process_obj?.cd_sistema_tramitacao));
          writ.system = system || null;

          const responsible = responsibleList.find(
            (r) => Number(r.cd_authuser) === Number(writ.process_obj?.cd_responsavel_tarefa)
          );
          writ.responsible = responsible || null;
        } else {
          writ.process_obj = null;
        }
      });

      this.context.commit("setPagination", { total: response?.data?.count || 0 });
      this.context.commit("setList", writs);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(payload: { filters: any }) {
    try {
      const response = await gojus.get<IntegrationWritT[]>({
        entity: "/andamentos/?mod_alvara=1",
        config: {
          query: { ...payload.filters },
          baseAPI: VUE_APP_API_PROCESS_URL,
          useEstablishment: true,
        },
      });
      const writs = response?.data || [];

      writs.forEach((writ) => {
        if (!writ.process_obj?.id) {
          writ.process_obj = null;
        }
      });

      return writs;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return [];
    }
  }

  @Action
  async getSummary(payload?: { filters: any }) {
    try {
      const response = await gojus.get({
        entity: "/andamentos/card/?mod_alvara=1",
        config: {
          query: { ...payload?.filters, cd_responsavel_tarefa: this.context.rootState.user.current?.id },
          baseAPI: VUE_APP_API_PROCESS_URL,
          useEstablishment: true,
        },
      });
      const summary = response?.data || {};
      this.context.commit("setSummary", summary);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async markAllAsReadByProcess(payload: { process: string }) {
    try {
      await gojus.get({
        entity: `/andamentos/ler/${payload.process}/?mod_alvara=1`,
        config: {
          sentry: false,
          baseAPI: VUE_APP_API_PROCESS_URL,
        },
      });

      return { success: true };
    } catch {
      return { success: false };
    }
  }
}

export default IntegrationWritModule;
