import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { PetitionFormT, PetitionT } from "@/store/integration/petition/types";
import { Pagination, PaginationResponse } from "@/store/types";

import gojus from "@/services/gojus";
import { generatePaginationOptions } from "@/utils/pagination";

const { VUE_APP_API_INTEGRATION_URL } = process.env;

@Module({ namespaced: true })
export class IntegrationPetitionModule extends VuexModule {
  list: PetitionT[] = [];
  initialForm: Partial<PetitionFormT> = {};

  filters: Record<string, any> = {};
  pagination: Pagination = { row: 10, total: 0, current: 1 };

  @Mutation
  setList(petitions: PetitionT[]) {
    this.list = petitions.map((petition) => ({
      ...petition,
      processNumber: petition.processos.map((item) => item.nr_processo).join(", "),
    }));
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  setPagination(pagination: any) {
    this.pagination = { ...this.pagination, ...pagination };
  }

  @Action
  async getStatus(payload: { id: number; cpf: string }) {
    try {
      const response = await gojus.get({
        entity: "peticionamento/status/update/",
        config: {
          query: {
            peticao: payload.id,
            cpf_adv: payload.cpf,
          },
          baseAPI: VUE_APP_API_INTEGRATION_URL,
        },
      });

      return response;
    } catch (error: any) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getList(payload?: { filters: Record<string, any> }) {
    try {
      const response = await gojus.get<PaginationResponse<PetitionT>>({
        entity: "peticionamento/",
        config: {
          query: { ...this.filters, ...payload?.filters, ...generatePaginationOptions(this.pagination) },
          baseAPI: VUE_APP_API_INTEGRATION_URL,
        },
      });

      this.context.commit("setList", response?.data?.results || []);
      this.context.commit("setPagination", { total: response?.data?.count || 0 });

      return response;
    } catch (error: any) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(payload: { form: PetitionFormT }) {
    try {
      const form = { ...payload.form };
      const response = await gojus.post({
        entity: "peticionamento/",
        data: form,
        config: {
          baseAPI: VUE_APP_API_INTEGRATION_URL,
        },
      });

      return { response, success: true };
    } catch (error: any) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async cancel(payload: { ids: number[] }) {
    try {
      const response = await gojus.delete({
        entity: "peticionamento/cancelar/",
        config: {
          query: {
            ids_petitions: payload.ids,
          },
          baseAPI: VUE_APP_API_INTEGRATION_URL,
        },
      });

      return { response, success: true };
    } catch (error: any) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }
}

export default IntegrationPetitionModule;
