import store from "@/store";
import { showApiError } from "@/utils/error";

type ApiRequestOptionsT = {
  endpoint: string;
  apiUrl: string;
  method?: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  payload?: Record<string, any> &
    Partial<{
      apiUrl: string;
      useCompany: boolean;
      useType: boolean;
      useJson: boolean;
    }>;
};

function generateHeaders(payload: ApiRequestOptionsT["payload"]): Record<string, string> {
  const token = localStorage.getItem("TOKEN");
  const headers: { [key: string]: string } = {
    Authorization: `Bearer ${token}`,
  };

  if (payload?.useType !== false) {
    headers["Content-Type"] = "application/json";
  }

  return headers;
}

function generateUrl(endpoint: string, payload: ApiRequestOptionsT["payload"] = {}): string {
  const handledPayload = { ...payload };

  const COMPANY_ID = localStorage.getItem("CD_EMPRESA");
  if (COMPANY_ID && handledPayload.useCompany !== false) {
    handledPayload.cd_empresa = COMPANY_ID;
  }

  if (handledPayload.pagination) {
    const { page, perPage } = handledPayload.pagination;
    handledPayload.page = page;
    handledPayload.nr_por_pagina = perPage;
    handledPayload.paginacao = true;
    handledPayload.ordenar_por = handledPayload.ordenar_por || handledPayload.pagination.sort || "-id";
    delete handledPayload.pagination;
  }

  if (!handledPayload.ordenar_por) {
    handledPayload.ordenar_por = "-id";
  }

  delete handledPayload.useJson;
  delete handledPayload.useType;
  delete handledPayload.useCompany;
  delete handledPayload.apiUrl;

  Object.entries(handledPayload).forEach(([key, value]) => {
    if (value === undefined) {
      delete handledPayload[key];
    }
  });

  const url = new URL(endpoint);
  url.search = new URLSearchParams(handledPayload).toString();

  return url.toString();
}

function checkResponseStatus(status: number, data: any): void {
  if (status === 401) {
    store.commit("user/updateSessionState", { active: false });
  }

  if (status >= 300) {
    throw { ...data, status };
  }

  if ((data as any)?.erro) {
    throw { ...data, status };
  }
}

type ResponseT<T> = Promise<{ data: T | null; response: Response | null; error?: unknown }>;

type ApiServiceT = {
  request<T>(options: ApiRequestOptionsT): ResponseT<T>;
  get<T>(endpoint: string, payload?: ApiRequestOptionsT["payload"]): ResponseT<T>;
  post<T>(endpoint: string, body: any, payload?: ApiRequestOptionsT["payload"]): ResponseT<T>;
  patch<T>(endpoint: string, body: any, payload?: ApiRequestOptionsT["payload"]): ResponseT<T>;
  delete<T>(endpoint: string, payload?: ApiRequestOptionsT["payload"]): ResponseT<T>;
};

export const gojus: ApiServiceT = {
  async request<T>(options: ApiRequestOptionsT) {
    try {
      const url = generateUrl(`${options.apiUrl}/${options.endpoint}`, options.payload);
      const requestOptions: RequestInit = {
        method: options.method,
        headers: { ...generateHeaders(options.payload?.type) },
        body: options.body ? JSON.stringify(options.body) : null,
      };

      const response = await fetch(url, requestOptions);
      const data = options.payload?.useJson === false ? await response.text() : await response.json();
      checkResponseStatus(response.status, data);

      return { data: data as T, response };
    } catch (error) {
      showApiError(error);
      return { data: null, response: null, error };
    }
  },

  get<T>(endpoint: string, payload: ApiRequestOptionsT["payload"] = {}) {
    return this.request<T>({ endpoint, method: "GET", payload, apiUrl: String(payload.apiUrl) });
  },

  post<T>(endpoint: string, body: any, payload: ApiRequestOptionsT["payload"] = {}) {
    return this.request<T>({ endpoint, method: "POST", body, payload, apiUrl: String(payload.apiUrl) });
  },

  patch<T>(endpoint: string, body: any, payload: ApiRequestOptionsT["payload"] = {}) {
    return this.request<T>({ endpoint, method: "PATCH", body, payload, apiUrl: String(payload.apiUrl) });
  },

  delete<T>(endpoint: string, payload: ApiRequestOptionsT["payload"] = {}) {
    return this.request<T>({ endpoint, method: "DELETE", payload, apiUrl: String(payload.apiUrl) });
  },
};
