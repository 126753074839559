<template>
  <div :class="componentCSS">
    <slot v-if="useHeader" name="header-container">
      <header class="c-dashboard-header-template">
        <p-progress-bar v-if="loading" mode="indeterminate" style="height: 0.3rem" :showValue="false" />
        <div v-else :style="{ height: '0.3rem' }" />

        <div class="top" v-if="useTop">
          <slot name="top-left">
            <h2 class="title">
              <slot name="title"></slot>
            </h2>
          </slot>
          <slot name="top-center"></slot>
          <slot name="top-right"></slot>
        </div>

        <slot name="options-container" v-if="useHeaderOptions">
          <div class="options">
            <div class="left">
              <slot name="filter" v-if="useLeftOptions">
                <p-button
                  :class="[(filtersQuantity || 0) > 0 ? 'p-button-outlined' : 'p-button-raised p-button-text']"
                  icon="pi pi-filter"
                  label="Filtrar"
                  :badge="String(filtersQuantity || '')"
                  badgeClass="p-badge-info"
                  @click="$emit('click-filter')"
                />
              </slot>

              <slot name="options"></slot>
            </div>

            <div class="center" v-if="useCenterOptions">
              <slot name="center-options"></slot>
            </div>

            <div class="right" v-if="useRightOptions">
              <slot name="right-options-items"></slot>

              <slot name="new-item" v-if="useNewItem">
                <p-button class="p-button-success p-button-raised" @click="$emit('click-new')">
                  <i class="fas fa-plus-circle" style="margin-right: 0.2rem; font-size: 1rem"></i>
                  <slot name="new"></slot>
                </p-button>
              </slot>
            </div>
          </div>
        </slot>
      </header>
    </slot>

    <main class="c-dashboard-main-template">
      <slot name="default"></slot>

      <slot name="sections-container" v-if="useSections">
        <div class="sections">
          <p-progress-bar v-if="sectionLoading" mode="indeterminate" style="height: 0.3rem" :showValue="false" />

          <p-button class="p-button-text back" label="Voltar" icon="fas fa-chevron-left" @click="$emit('click-back')" />
          <slot name="sections"></slot>
        </div>
      </slot>

      <slot name="filters-container" v-if="useFilters">
        <div class="filters">
          <slot name="filters"></slot>
        </div>
      </slot>

      <div class="cards-container" v-if="useCards">
        <slot name="cards-container">
          <div class="cards">
            <slot name="cards"></slot>
          </div>
        </slot>
      </div>

      <div class="subtitles-container" v-if="$slots['subtitles-container'] || $slots['subtitles']">
        <slot name="subtitles-container">
          <div class="subtitles">
            <slot name="subtitles"></slot>
          </div>
        </slot>
      </div>

      <slot name="table-container" v-if="useTable">
        <div class="table">
          <p-progress-bar v-if="tableLoading" mode="indeterminate" style="height: 0.3rem" :showValue="false" />
          <div v-else :style="{ height: '0.3rem' }" />

          <slot name="table"></slot>
        </div>
      </slot>

      <slot name="form-container" v-if="useForm">
        <c-divider v-if="useFormDivider" />

        <div class="form">
          <p-button
            class="p-button-text back"
            label="Voltar"
            icon="fas fa-chevron-left"
            @click="$emit('click-back')"
            v-if="useFormBack"
          />
          <slot name="form"></slot>
        </div>
      </slot>

      <slot name="content-container" v-if="useContent">
        <div class="content">
          <slot name="content"></slot>
        </div>
      </slot>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CDivider from "@/components/divider.vue";
import PProgressBar from "primevue/progressbar/ProgressBar.vue";

@Component({ components: { CDivider, PProgressBar } })
export default class CDashboardTemplate extends Vue {
  @Prop({ required: false, default: false }) readonly loading!: boolean;
  @Prop({ required: false, default: false }) readonly tableLoading!: boolean;
  @Prop({ required: false, default: false }) readonly sectionLoading!: boolean;
  @Prop({ required: false, default: true }) readonly useHeader!: boolean;
  @Prop({ required: false, default: true }) readonly useTop!: boolean;
  @Prop({ required: false, default: false })
  readonly useHeaderOptions!: boolean;
  @Prop({ required: false, default: true }) readonly useLeftOptions!: boolean;
  @Prop({ required: false, default: false })
  readonly useCenterOptions!: boolean;
  @Prop({ required: false, default: true }) readonly useRightOptions!: boolean;
  @Prop({ required: false, default: true }) readonly useNewItem!: boolean;
  @Prop({ required: false, default: false }) readonly useFilters!: boolean;
  @Prop({ required: false, default: false }) readonly useSections!: boolean;
  @Prop({ required: false, default: false }) readonly useCards!: boolean;
  @Prop({ required: false, default: false }) readonly useTable!: boolean;
  @Prop({ required: false, default: false }) readonly useForm!: boolean;
  @Prop({ required: false, default: false }) readonly useContent!: boolean;
  @Prop({ required: false, default: true }) readonly useFormBack!: boolean;
  @Prop({ required: false, default: false })
  readonly useFormDivider!: boolean;
  @Prop({ required: false, default: 0 }) readonly filtersQuantity!: number;

  get componentCSS() {
    return ["c-dashboard-template", "p-card", this.loading ? "loading" : ""];
  }
}
</script>

<style lang="scss" scoped>
.c-dashboard-template {
  --padding-y: 12px;
  --padding-x: 24px;

  position: relative;
  height: 100%;
  border: 1px solid #dee2e6;
  box-shadow: none;
  margin-bottom: 8px;
  border-radius: 8px;

  &.loading {
    &::before {
      content: "";
      width: 100%;
      height: 100%;

      z-index: 99;

      position: absolute;

      background-color: #0008;
      animation: loader 4s linear infinite;
    }
  }

  .c-divider {
    margin: 0 var(--padding-x);
  }

  .p-button {
    i {
      padding: 0 3px;
      font-size: 0.8rem;
    }

    &.back {
      // padding: 0;
      margin: 6px 0;
      box-shadow: unset;
    }
  }

  .c-dashboard-header-template {
    .top {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      padding: 12px 24px;

      .title {
        font-size: 1.1rem;
        font-weight: 700;

        i {
          margin-right: 8px;
        }
      }
    }

    .options {
      display: flex;
      justify-content: space-between;
      padding: 0.8rem 1.7rem 0.8rem 1.4rem;
      border: 1px solid #dee2e6;
      border-left-width: 0;
      border-right-width: 0;
      background-color: var(--zinc-50);

      .left {
        .p-button {
          + .p-button {
            margin-left: 12px;
          }
        }
      }
    }
  }

  .c-dashboard-main-template {
    position: relative;

    > .cards-container {
      padding: 0.8rem 1.3rem 0.8rem 1rem;
    }

    > .cards,
    > .cards-container > .cards {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    > .subtitles-container {
      padding: 0 1.3rem 0.8rem 1rem;
    }

    > .subtitles,
    > .subtitles-container > .subtitles {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    > .form {
      padding: var(--padding-y) var(--padding-x);

      .back {
        padding: 0.2rem 0;
      }
    }

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
    }

    > .sections {
      padding: var(--padding-y) var(--padding-x);

      .c-dashboard-section-template + .c-dashboard-section-template {
        margin-top: 24px;
      }

      .back {
        padding: 0.2rem 0;
      }
    }
  }

  @keyframes loader {
    0% {
      background-color: #0008;
    }
    50% {
      background-color: #0001;
    }
    100% {
      background-color: #0008;
    }
  }
}
</style>
