<template>
  <c-form-template class="c-task-finish-modal" :useFooter="false" @submit="$emit('submit')">
    <p class="subtitle" v-if="props.task">{{ props.task.nm_titulo }}</p>
    <p class="responsible-warning" v-if="!props.hasResponsible">
      <i class="fas fa-warning"></i>
      A tarefa está sem um responsável atribuído!
    </p>
    <c-form-field-template width="100%" :useLabel="props.label">
      <template #label>{{ props.label }}</template>
      <p-textarea v-model="field" :autoResize="true" rows="4" cols="auto" required autofocus />
    </c-form-field-template>
    <c-form-field-template width="100%">
      <template #label>Anexos {{ addPetition ? "(apenas PDF)" : "" }}</template>
      <p-file-upload
        ref="fileUpload"
        mode="advanced"
        chooseLabel="Adicionar"
        uploadLabel="remove"
        cancelLabel="Limpar"
        :accept="addPetition ? '.pdf' : ''"
        :multiple="true"
        :customUpload="true"
        @uploader="handleFileUpload"
      >
        <template #empty>
          <p>Arraste e solte um arquivo aqui.</p>
        </template>
      </p-file-upload>
    </c-form-field-template>
    <div class="p-field-checkbox" v-if="$store.state.user.permissions.integrationModule && props.task.nr_processo">
      <p-checkbox v-model="addPetition" :binary="true" />
      <label class="p-checkbox-box-label" @click="addPetition = !addPetition">
        Deseja adicionar um peticionamento?
      </label>
    </div>
    <p class="petition-info" v-if="addPetition">
      <i class="fas fa-info-circle"></i>
      O primeiro anexo adicionado será a petição.
    </p>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";

@Component({ components: { CFormFieldTemplate, CFormTemplate } })
export default class CTextAreaModal extends Vue {
  @Prop({ required: true }) readonly props!: any;
  field = "";
  files: File[] = [];
  addPetition = false;

  @Ref() readonly fileUpload!: any;

  handleFileUpload(event: { files: File[] }) {
    this.files = event.files;
  }

  getFields() {
    if (!this.field) {
      this.$store.commit("global/updateToast", [
        { summary: `O campo "${this.props.label}" é obrigatório!`, severity: "warn" },
      ]);

      return { hasError: true };
    }

    this.fileUpload.upload();

    return { field: this.field, files: this.files, addPetition: this.addPetition };
  }

  reset() {
    this.field = "";
  }

  hideFileUploadButton() {
    this.fileUpload.$children.forEach((element: any) => {
      if (element.label === "remove") element.$el.style.display = "none";
    });
  }

  mounted() {
    this.hideFileUploadButton();
  }

  created() {
    this.field = this.props?.field || "";
  }
}
</script>

<style lang="scss">
.p-dialog.task-finish {
  width: 100%;
  max-width: 600px;

  .p-field-checkbox {
    margin: 1rem 0 0 0.4rem;

    .p-checkbox-box-label {
      margin-left: 0.2rem;
      cursor: pointer;
    }
  }

  .subtitle {
    margin: 0 0 16px 2px;
    font-size: 12px;
    font-weight: 500;
  }

  .responsible-warning {
    margin: 0px 0 12px 2px;
    color: var(--danger-color);
    font-size: 13px;
    font-weight: 700;
    background-color: #fee2e2;
    padding: 0.5rem 0.8rem;
    border-radius: 2px;

    i {
      margin-right: 0.2rem;
    }
  }

  .petition-info {
    margin: 8px 0 16px 32px;
    color: var(--info-color);
    font-size: 13px;
    font-weight: 600;
    padding: 0.5rem 0.8rem;
    background-color: #e0f2fe;
    border-radius: 2px;

    i {
      margin-right: 0.2rem;
    }
  }

  .p-dialog-header {
    padding: 1rem 1rem 0.25rem;
  }

  .p-inputtextarea {
    max-height: 240px;
  }

  .p-dialog-content {
    overflow-y: auto !important;
    padding: 0 1rem 1rem 1rem;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px #0000004d;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #fcfcfc;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #3273dc;
    }
  }

  .p-dialog-footer {
    padding: 1rem;
  }
}
</style>
