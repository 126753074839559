type EnvOptionsT = { [key: string]: string } & {
  NODE_ENV: "production" | "development";
};

const env = (() => {
  try {
    return process?.env || {};
  } catch {
    return {};
  }
})() as EnvOptionsT;
export const {
  NODE_ENV,
  NODE_VERSION,
  VUE_APP_API_BASE_URL,
  VUE_APP_API_LOGISTICS_URL,
  VUE_APP_API_LOCATION_URL,
  VUE_APP_API_COLLECTION_URL,
  VUE_APP_API_FINANCIAL_URL,
  VUE_APP_API_JURIDICAL_URL,
  VUE_APP_API_DEPARTMENT_URL,
  VUE_APP_API_TASK_URL,
  VUE_APP_VIACEP_API,
  VUE_APP_API_ANALYTICS_URL,
  VUE_APP_API_INTEGRATION_URL,
} = env;
