import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import gojus from "@/services/gojus";

import IntegrationPublicationModule from "@/store/integration/publication/module";
import IntegrationFoundModule from "./found/module";
import IntegrationMovementModule from "./movement/module";
import { IntegrationSystemT } from "./types";
import IntegrationPetitionModule from "./petition/module";
import IntegrationWritModule from "./writ/module";

const { VUE_APP_API_PROCESS_URL } = process.env;

@Module({ namespaced: true })
class IntegrationModule extends VuexModule {
  systems: IntegrationSystemT[] = [];

  static modules = {
    writ: IntegrationWritModule,
    publication: IntegrationPublicationModule,
    found: IntegrationFoundModule,
    movement: IntegrationMovementModule,
    petition: IntegrationPetitionModule,
  };

  @Mutation
  setSystems(systems: IntegrationSystemT[]) {
    this.systems = systems;
  }

  @Action
  async getSystems(payload?: { filters?: Record<string, any> }) {
    try {
      const response = await gojus.get<any[]>({
        entity: "/systems",
        config: { query: { ...payload?.filters }, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.commit("setSystems", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default IntegrationModule;
