import type { ToastMessageT, ToastT } from "@/stores/app/types";

import { ref } from "vue";
import { defineStore } from "pinia";

export const useAppStore = defineStore("app", () => {
  const toast = ref<ToastT>({ status: "hidden", message: null });
  let timeout: any = null;

  function hideToast() {
    if (timeout) clearTimeout(timeout);

    toast.value.status = "hidden";
    toast.value.message = null;
    timeout = null;
  }

  function showToast(message: ToastMessageT) {
    toast.value.status = "visible";
    toast.value.message = { ...message, life: message.life ?? `${message.title}${message.description}`.length * 100 };

    timeout = setTimeout(hideToast, toast.value.message.life);
  }

  return { toast, showToast, hideToast };
});
