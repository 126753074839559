import gojus from "@/services/gojus";
import {
  Lawsuit,
  LawsuitArea,
  LawsuitAreaForm,
  LawsuitFilters,
  LawsuitForm,
  LawsuitInstance,
  LawsuitInstanceForm,
  LawsuitLiminar,
  LawsuitLiminarForm,
  LawsuitLitisconsorte,
  LawsuitLitisconsorteForm,
  LawsuitStage,
  LawsuitStageForm,
  LawsuitStrategy,
  LawsuitStrategyForm,
  LawsuitThink,
  LawsuitThinkForm,
  ProcessingSystem,
} from "@/store/lawsuit/types";

import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_JURIDICAL_URL, VUE_APP_API_PROCESS_URL, VUE_APP_API_EXPEDIENT_URL } = process.env;

export class LawsuitService {
  endpoint = "processos/";
  cdEndpoint = (cd: number) => `processos/${cd}/`;
  thinkEndpoint = "apensamentos/";
  cdThinkEndPoint = (cd: number) => `apensamentos/${cd}/`;
  cdEndEndpoint = (cd: number) => `processos/encerar/${cd}/`;
  cdResponsibleLawsuitEndPoint = (cd: number) => `processos/responsavel/${cd}/`;
  areaEndpoint = "areas/";
  areaCDEndpoint = (cd: number) => `areas/${cd}/`;
  instanceEndpoint = "instancias/";
  instanceCDEndpoint = (cd: number) => `instancias/${cd}/`;
  stageEndpoint = "fases/";
  stageCDEndpoint = (cd: number) => `fases/${cd}/`;
  litisconsorteEndpoint = "litis-consorte/";
  litisconsorteCDEndpoint = (cd: number) => `litis-consorte/${cd}/`;
  litisconsorteCDCDEndpoint = (cd_processo: number, cd_litisconsorte: number) =>
    `litis-consorte/${cd_processo}/${cd_litisconsorte}/`;
  liminarEndpoint = "liminares/";
  liminarCDEndpoint = (cd: number) => `liminares/${cd}/`;
  liminarCDCDEndpoint = (cd_processo: number, cd_liminar: number) => `liminares/${cd_liminar}/${cd_processo}/`;
  fileEndpoint = "arquivos/";
  fileCDEndpoint = (cd: number) => `arquivos/${cd}/`;
  fileIncrementDownloadEndpoint = (cd: number) => `arquivos/${cd}/incremente-download/`;
  strategyEndpoint = "estrategias/";
  strategyCDEndpoint = (cd: number) => `estrategias/${cd}/`;
  strategyCDCDEndpoint = (cd_processo: number, cd_estrategia: number) => `estrategias/${cd_processo}/${cd_estrategia}/`;
  processingSystemEndpoint = "systems";
  reasonForProcessClosing = "motivo-encerramento/";
  cdReasonForProcessClosing = (cd: number | string) => `motivo-encerramento/${cd}/`;
  summary = "processos/resumo/";
  updateMovementsEndpoint = "api/movements-update";
  movementsInLastDay = "processos/movimentados-dia-anterior/";

  async get(cd: number) {
    const { data } = await gojus.get<Lawsuit>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return { data };
  }

  async getWithNumber(nr_processo: string) {
    const response = await gojus.get<Lawsuit[]>({
      entity: this.endpoint,
      config: { query: { nr_processo }, baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getMovementsInLastDay() {
    const response = await gojus.get<{ quantidade: number }>({
      entity: this.movementsInLastDay,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getAllThinks(cd: number) {
    const response = await gojus.get<LawsuitThink[]>({
      entity: this.thinkEndpoint,
      config: {
        query: { cd_processo: cd },
        baseAPI: VUE_APP_API_JURIDICAL_URL,
      },
    });

    return response;
  }

  async getAll(pagination: PaginationParams | null, filters: any) {
    if (filters.etiquetas?.length) {
      filters.etiquetas = filters.etiquetas.map((item: any) => item.id);
    }

    const { data } = await gojus.get<PaginationResponse<Lawsuit>>({
      entity: this.endpoint,
      config: {
        query: { ...filters, ...pagination },
        baseAPI: VUE_APP_API_JURIDICAL_URL,
        useCompany: true,
      },
    });

    return { data };
  }

  async getAllReasonForProcessClosing() {
    const response = await gojus.get<any[]>({
      entity: this.reasonForProcessClosing,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async createReasonForProcessClosing(form: any) {
    const response = await gojus.post<any[]>({
      entity: this.reasonForProcessClosing,
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async responsibleLawsuit(cd: number) {
    const response = await gojus.get<any>({
      entity: this.cdResponsibleLawsuitEndPoint(cd),
      config: {
        baseAPI: VUE_APP_API_JURIDICAL_URL,
      },
    });

    return response;
  }

  async create(form: LawsuitForm) {
    const response = await gojus.post<Lawsuit, LawsuitForm>({
      entity: this.endpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async edit(cd: number, form: Lawsuit) {
    const response = await gojus.patch<Lawsuit>({
      entity: this.cdEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getLawsuitSummaryFilter() {
    const response = await gojus.get<any>({
      entity: this.summary,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async editStrategic(cd: number, ie_estrategico: boolean) {
    const response = await gojus.patch<any>({
      entity: this.cdEndpoint(cd),
      data: { ie_estrategico },
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async end(cd: number, form: any) {
    const response = await gojus.patch<Lawsuit>({
      entity: this.cdEndEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async addAttach(cd_processo: number, cd_processo_apenso: number, form: { [key: string]: any }) {
    try {
      return await gojus.post<LawsuitThinkForm, LawsuitThinkForm>({
        entity: this.thinkEndpoint,
        data: { cd_processo, cd_processo_apenso, ...form },
        config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
      });
    } catch {
      return false;
    }
  }

  async updateMovements(process: number) {
    try {
      return await gojus.get({
        entity: this.updateMovementsEndpoint,
        config: { query: { process }, baseAPI: VUE_APP_API_EXPEDIENT_URL },
      });
    } catch {
      return false;
    }
  }

  async deleteAttach(cd_processo: number, cd_processo_apenso: number) {
    try {
      const remove = (cd: number) => {
        return gojus.delete({
          entity: this.cdThinkEndPoint(cd),
          config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
        });
      };
      const { data } = await this.getAllThinks(cd_processo);

      for (const item of data) {
        if (item.cd_processo_apenso === cd_processo_apenso) {
          return await remove(item.id);
        }
      }
      return false;
    } catch {
      return false;
    }
  }

  async getLitisconsorte(cd: number) {
    const response = await gojus.get<LawsuitLitisconsorte>({
      entity: this.litisconsorteCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async createLitisconsorte(form: LawsuitLitisconsorteForm) {
    const response = await gojus.post<LawsuitLitisconsorte, LawsuitLitisconsorteForm>({
      entity: this.litisconsorteEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async updateLitisconsorte(cd_processo: number, cd_litisconsorte: number, form: LawsuitLitisconsorteForm) {
    const response = await gojus.patch<LawsuitLitisconsorte, LawsuitLitisconsorteForm>({
      entity: this.litisconsorteCDCDEndpoint(cd_processo, cd_litisconsorte),
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async deleteLitisconsorte(cd_processo: number, cd_litisconsorte: number) {
    const response = await gojus.delete({
      entity: this.litisconsorteCDCDEndpoint(cd_processo, cd_litisconsorte),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return response;
  }

  async getLiminar(cd: number) {
    const response = await gojus.get<LawsuitLiminar>({
      entity: this.liminarCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async createLiminar(form: LawsuitLiminarForm) {
    const response = await gojus.post<LawsuitLiminar, LawsuitLiminarForm>({
      entity: this.liminarEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async editLiminar(cd_processo: number, cd_liminar: number, form: LawsuitLiminarForm) {
    const response = await gojus.patch<LawsuitLiminar, LawsuitLiminarForm>({
      entity: this.liminarCDCDEndpoint(cd_processo, cd_liminar),
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async deleteLiminar(cd_processo: number, cd_liminar: number) {
    const response = await gojus.delete({
      entity: this.liminarCDCDEndpoint(cd_processo, cd_liminar),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return response;
  }

  async getArea(cd: number) {
    const response = await gojus.get<LawsuitArea>({
      entity: this.areaCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getAreas() {
    const response = await gojus.get<LawsuitArea[]>({
      entity: this.areaEndpoint,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async createArea(form: LawsuitAreaForm) {
    form.nm_area = form.nm_area?.toUpperCase();

    const response = await gojus.post<LawsuitArea, LawsuitAreaForm>({
      entity: this.areaEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async editArea(cd: number, form: LawsuitAreaForm) {
    form.nm_area = form.nm_area?.toUpperCase();

    const response = await gojus.patch<LawsuitArea, LawsuitAreaForm>({
      entity: this.areaCDEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async deleteArea(cd: number) {
    const response = await gojus.delete({
      entity: this.areaCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return response;
  }

  async getInstance(cd: number) {
    const response = await gojus.get<LawsuitInstance>({
      entity: this.instanceCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getInstances() {
    const response = await gojus.get<LawsuitInstance[]>({
      entity: this.instanceEndpoint,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async createInstance(form: LawsuitInstanceForm) {
    form.nm_instancia = form.nm_instancia?.toUpperCase();

    const response = await gojus.post<LawsuitInstance, LawsuitInstanceForm>({
      entity: this.instanceEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async editInstance(cd: number, form: LawsuitInstanceForm) {
    form.nm_instancia = form.nm_instancia?.toUpperCase();

    const response = await gojus.patch<LawsuitInstance, LawsuitInstanceForm>({
      entity: this.instanceCDEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async deleteInstance(cd: number) {
    const response = await gojus.delete({
      entity: this.instanceCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getStage(cd: number) {
    const response = await gojus.get<LawsuitStage>({
      entity: this.stageCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getStages() {
    const response = await gojus.get<LawsuitStage[]>({
      entity: this.stageEndpoint,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async createStage(form: LawsuitStageForm) {
    form.nm_fase = form.nm_fase?.toUpperCase();

    const response = await gojus.post<LawsuitStage, LawsuitStageForm>({
      entity: this.stageEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async editStage(cd: number, form: LawsuitStageForm) {
    form.nm_fase = form.nm_fase?.toUpperCase();

    const response = await gojus.patch<LawsuitStage, LawsuitStageForm>({
      entity: this.stageCDEndpoint(cd),
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async deleteStage(cd: number) {
    const response = await gojus.delete({
      entity: this.stageCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getStrategies(cd: number) {
    const response = await gojus.get<LawsuitStrategy[]>({
      entity: this.strategyCDEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async createStrategy(form: LawsuitStrategyForm) {
    const response = await gojus.post<LawsuitStrategy, LawsuitStrategyForm>({
      entity: this.strategyEndpoint,
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async editStrategy(cd_processo: number, cd_estrategia: number, form: { ds_estrategia: string }) {
    const response = await gojus.patch<LawsuitStrategy, { ds_estrategia: string }>({
      entity: this.strategyCDCDEndpoint(cd_processo, cd_estrategia),
      data: form,
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async deleteStrategy(cd_processo: number, cd_estrategia: number) {
    const response = await gojus.delete({
      entity: this.strategyCDCDEndpoint(cd_processo, cd_estrategia),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async getProcessingSystems(payload?: { filters?: Record<string, any> }) {
    try {
      const response = await gojus.get<ProcessingSystem[]>({
        entity: this.processingSystemEndpoint,
        config: { query: { ...payload?.filters }, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
      return null;
    }
  }
}

export default LawsuitService;
