import { formatCPF, formatCPFToLGPD, formatDate, formatProcessNumberInCnjPattern } from "@/utils/formatters";
import { Lawsuit, LawsuitForm, LawsuitLiminarForm, LawsuitLitisconsorte } from "@/store/lawsuit/types";
import { getLawsuitRisk, getTypeOfLawyer } from "@/utils/acronyms";
import { addMoneyMask } from "@/utils/mask";
import { InfoItem } from "@/utils/types";
import store from "@/store";

export function handleGeneralInfoItems(lawsuit: Lawsuit) {
  const items: InfoItem[] = [];

  if (lawsuit.id) {
    items.push({
      prefix: "ID: #",
      text: lawsuit.id,
      title: "ID",
      icon: "fas fa-fingerprint",
    });
  }

  if (lawsuit.nr_processo) {
    items.push({
      prefix: "Processo: ",
      text: formatProcessNumberInCnjPattern(lawsuit.nr_processo),
      value: lawsuit.nr_processo,
      icon: "fas fa-gavel",
    });
  }

  if (lawsuit.ds_pasta)
    items.push({
      prefix: "Pasta: ",
      text: lawsuit.ds_pasta,
      icon: "fas fa-paste",
    });

  if (lawsuit.nm_magistrado)
    items.push({
      prefix: "Magistrado: ",
      text: lawsuit.nm_magistrado,
      icon: "fas fa-user-graduate",
    });

  if (lawsuit.ob_orgao?.nm_foro)
    items.push({
      prefix: "Foro: ",
      text: lawsuit.ob_orgao.nm_foro,
      icon: "fas fa-landmark",
      title: "Ver detalhes do Foro",
      to: {
        name: "forum-details",
        params: { cd: lawsuit.ob_orgao.id.toString() },
      },
    });

  if (lawsuit.cd_area?.nm_area)
    items.push({
      prefix: "Area: ",
      text: lawsuit.cd_area.nm_area,
      icon: "fas fa-network-wired",
      title: "Ver detalhes da Area",
      to: {
        name: "areas-details",
        params: { cd: lawsuit.cd_area.id.toString() },
      },
    });

  if (lawsuit.cd_instancia?.nm_instancia)
    items.push({
      prefix: "Instância: ",
      text: lawsuit.cd_instancia.nm_instancia,
      icon: "fas fa-people-arrows",
      title: "Ver detalhes da Instância",
      to: {
        name: "instancia-details",
        params: { cd: lawsuit.cd_instancia.id.toString() },
      },
    });

  if (lawsuit.ds_tipo_acao)
    items.push({
      prefix: "Ação: ",
      text: lawsuit.ds_tipo_acao,
      icon: "fab fa-typo3",
      title: "Ação",
    });

  if (lawsuit.cd_fase?.nm_fase)
    items.push({
      prefix: "Fase: ",
      text: lawsuit.cd_fase.nm_fase,
      icon: "fas fa-layer-group",
      title: "Fase",
    });

  if (lawsuit.nm_cidade)
    items.push({
      prefix: "Comarca: ",
      text: lawsuit.nm_cidade + " / " + lawsuit.nm_estado,
      title: "Cidade e Estado",
      icon: "fas fa-street-view",
    });

  if (lawsuit.ie_risco)
    items.push({
      prefix: "Risco: ",
      text: getLawsuitRisk(lawsuit.ie_risco)?.label || "",
      value: lawsuit.ie_risco,
      icon: "fas fa-exclamation-triangle",
    });

  if (lawsuit.ob_responsavel_tarefa?.nm_funcionario)
    items.push({
      prefix: "Responsável: ",
      text: lawsuit.ob_responsavel_tarefa.nm_funcionario,
      icon: "fas fa-user-check",
      title: "Fase",
    });

  return items;
}

export function handleLitisconsorteInfoItems(
  litisconsorte: LawsuitLitisconsorte[],
  lawsuit?: Lawsuit,
  advogado?: boolean
) {
  const items: InfoItem[] = [];

  litisconsorte.forEach((item) => {
    if (!item.nm_autor) return;

    const tax = item.nr_cnpj_autor || formatCPFToLGPD(item.nr_cpf_autor) || "";
    items.push({
      prefix: "Autor: ",
      text: `${item.nm_autor || ""} ${tax ? `(${tax})` : ""}`,
      icon: "fas fa-user",
      to:
        lawsuit?.ie_posicao_cliente === "A" && lawsuit?.cd_cliente
          ? { name: "lawsuit-customer-details", params: { cd: item.cd_cliente?.toString() } }
          : undefined,
    });
  });

  litisconsorte.forEach((item) => {
    if (!item.nm_reu) return;

    const tax = item.nr_cnpj_reu || formatCPFToLGPD(item.nr_cpf_reu) || "";
    items.push({
      prefix: "Réu: ",
      text: `${item.nm_reu || ""} ${tax ? `(${tax})` : ""}`,
      icon: "fas fa-user",
      to:
        lawsuit?.ie_posicao_cliente === "P" && lawsuit?.cd_cliente
          ? { name: "lawsuit-customer-details", params: { cd: item.cd_cliente?.toString() } }
          : undefined,
    });
  });

  if (advogado) {
    if (lawsuit?.nm_advogado_parte_contraria)
      items.push({
        prefix: "Advogado da Parte Contraria: ",
        text: lawsuit.nm_advogado_parte_contraria,
        icon: "fas fa-user-tie",
      });

    if (lawsuit?.ie_tipo_advogado)
      items.push({
        prefix: "Tipo Advogado da Parte Contraria: ",
        text: getTypeOfLawyer(lawsuit?.ie_tipo_advogado)?.label || "",
        icon: "fas fa-user-tie",
      });

    if (lawsuit?.nm_parceiro)
      items.push({
        prefix: "Parceiro/Indicação: ",
        text: lawsuit.nm_parceiro,
        icon: "fa-solid fa-people-arrows-left-right",
      });
  }

  return items;
}

export function handleValuesInfoItems(lawsuit: Lawsuit) {
  const items: InfoItem[] = [];

  if (lawsuit.nr_valor_causa !== undefined)
    items.push({
      text: addMoneyMask(lawsuit.nr_valor_causa),
      value: lawsuit.nr_valor_causa,
      prefix: "Causa: ",
      icon: "fas fa-dollar-sign",
    });

  if (lawsuit.nr_valor_condenacao !== undefined)
    items.push({
      text: addMoneyMask(lawsuit.nr_valor_condenacao),
      value: lawsuit.nr_valor_condenacao,
      prefix: "Condenação: ",
      icon: "fas fa-dollar-sign",
    });

  if (lawsuit.nr_valor_sucumbencia !== undefined)
    items.push({
      text: addMoneyMask(lawsuit.nr_valor_sucumbencia),
      value: lawsuit.nr_valor_sucumbencia,
      prefix: "Sucumbência: ",
      icon: "fas fa-dollar-sign",
    });

  if (lawsuit.nr_valor_pago_processo !== undefined)
    items.push({
      text: addMoneyMask(lawsuit.nr_valor_pago_processo),
      value: lawsuit.nr_valor_pago_processo,
      prefix: "Pago: ",
      icon: "fas fa-dollar-sign",
    });

  if (lawsuit.nr_valor_pago_recebido !== undefined)
    items.push({
      text: addMoneyMask(lawsuit.nr_valor_pago_recebido),
      value: lawsuit.nr_valor_pago_recebido,
      prefix: "Recebido: ",
      icon: "fas fa-dollar-sign",
    });

  if (lawsuit.nr_valor_perda !== undefined)
    items.push({
      text: addMoneyMask(lawsuit.nr_valor_perda),
      value: lawsuit.nr_valor_perda,
      prefix: "Perda: ",
      icon: "fas fa-dollar-sign",
    });

  return items;
}

export function handleDateInfoItems(lawsuit: Lawsuit) {
  const items: InfoItem[] = [];

  if (lawsuit.dt_criado) {
    const date = formatDate(lawsuit.dt_criado);

    items.push({
      prefix: "Criação: ",
      text: date,
      icon: "fas fa-calendar-alt",
      title: `Data da criação, ${date}`,
    });
  }

  if (lawsuit.dt_distribuicao) {
    const date = formatDate(lawsuit.dt_distribuicao);

    items.push({
      prefix: "Distribuição: ",
      text: date,
      icon: "fas fa-calendar-alt",
      title: `Data da distribuição, ${date}`,
    });
  }

  if (lawsuit.dt_citacao) {
    const date = formatDate(lawsuit.dt_citacao);

    items.push({
      prefix: "Citação: ",
      text: date,
      icon: "fas fa-calendar-alt",
      title: `Data da citação, ${date}`,
    });
  }

  if (lawsuit.dt_recebimento_processo_escritorio) {
    const date = formatDate(lawsuit.dt_recebimento_processo_escritorio);

    items.push({
      prefix: "Recebido (Escritório): ",
      text: date,
      icon: "fas fa-calendar-alt",
      title: `Recebido pelo escritório em ${date}`,
    });
  }

  if (lawsuit.dt_enceramento) {
    const date = formatDate(lawsuit.dt_enceramento);

    items.push({
      prefix: "Encerramento: ",
      text: date,
      icon: "fas fa-calendar-alt",
      title: `Data do encerramento, ${date}`,
    });
  }

  if (lawsuit.dt_deletado) {
    const date = formatDate(lawsuit.dt_deletado);
    items.push({
      prefix: "Deletado em: ",
      text: date,
      icon: "fas fa-calendar-alt",
      title: `Data de exclusão, ${date}`,
    });
  }

  return items;
}

export function handleAuditInfoItems(log: any) {
  const items = [...handleGeneralInfoItems(log), ...handleValuesInfoItems(log), ...handleDateInfoItems(log)];

  return items;
}

export type SubmitParams = {
  form: LawsuitForm;
  autores: any[];
  reus: any[];
  files: File[];
  liminares: LawsuitLiminarForm[];
};

export async function lawsuitValidateForm(lawsuit: LawsuitForm, isEditForm: boolean) {
  if (!lawsuit.nm_cidade) {
    return { field: "city", error: 'O campo "Comarca" é obrigatório!' };
  }
  if (!lawsuit.cd_instancia) {
    return { field: "instance", error: 'O campo "Instância" é obrigatório!' };
  }
  if (!lawsuit.cd_area) {
    return { field: "area", error: 'O campo "Área do Direito" é obrigatório!' };
  }
  if (!lawsuit.cd_fase) {
    return { field: "fase", error: 'O campo "Fase do Processo" é obrigatório!' };
  }
  if (!lawsuit.cd_cliente) {
    return {
      field: "customer",
      error: `O campo "Nome do ${lawsuit.ie_posicao_cliente === "P" ? "Réu" : "Autor"}" é obrigatório!`,
    };
  }
  if (!lawsuit.ie_posicao_cliente) {
    return {
      field: "posicaoCliente",
      error: 'O campo "Posição Cliente" é obrigatório!',
    };
  }

  try {
    if (!isEditForm && lawsuit.nr_processo && lawsuit.ie_judicial) {
      const response = await store.state.lawsuit.service.getAll(
        {},
        { nr_processo: lawsuit.nr_processo, cd_sistema_tramitacao: lawsuit.cd_sistema_tramitacao }
      );

      if (response.data.length > 0) {
        return { field: "process", error: "Já existe um processo registrado com o mesmo número." };
      }
    }

    return { error: null, field: null };
  } catch {
    return { error: null, field: null };
  }
}
