import { render, staticRenderFns } from "./certificate-upload.vue?vue&type=template&id=031323f1"
import script from "./certificate-upload.vue?vue&type=script&lang=ts"
export * from "./certificate-upload.vue?vue&type=script&lang=ts"
import style0 from "./certificate-upload.vue?vue&type=style&index=0&id=031323f1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports